import {
    GET_PRESSWORKS_STATED,
    GET_PRESSWORKS,
    GET_PRESSWORKS_ENDED,
    ADD_PRESSWORK_STATED,
    ADD_PRESSWORK,
    ADD_PRESSWORK_ENDED,
    EDIT_PRESSWORK_STATED,
    EDIT_PRESSWORK,
    EDIT_PRESSWORK_ENDED,
    GET_PRESSWORK_STATED,
    GET_PRESSWORK,
    GET_PRESSWORK_ENDED,
    GET_ALL_PRESSWORKS_STATED,
    GET_ALL_PRESSWORKS,
    GET_ALL_PRESSWORKS_ENDED
} from "../types/presswork_type";

const initialState = {
    pressworks_loading: true,
    pressworks: null,
    page: null,
    pages: null,
    total_pressworks: 0,

    presswork: null,
    presswork_loading: null,

    loading: true,

    presswork_message: null,
    all_pressworks: null,
    all_pressworks_loading: null,
    add_presswork_loading: true,
    edit_presswork_loading: true
};

export const presswork_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PRESSWORKS_STATED:
            return {
                ...state,
                pressworks: null,
                pages: null,
                page: null,
                total_pressworks: 0,
                pressworks_loading: true
            };
        case GET_PRESSWORKS:
            return {
                ...state,
                pressworks: payload.pressworks,
                pages: payload.pages,
                page: payload.page,
                total_pressworks: payload.count
            };
        case GET_PRESSWORKS_ENDED:
            return {
                ...state,
                pressworks_loading: false
            };
        case GET_ALL_PRESSWORKS_STATED:
            return {
                ...state,
                all_pressworks_loading: true,
                all_pressworks: null
            };
        case GET_ALL_PRESSWORKS:
            return {
                ...state,
                all_pressworks: payload
            };
        case GET_ALL_PRESSWORKS_ENDED:
            return {
                ...state,
                all_pressworks_loading: false
            };

        case ADD_PRESSWORK_STATED:
            return {
                ...state,
                presswork_message: null,
                add_presswork_loading: true
            };
        case ADD_PRESSWORK:
            return {
                ...state,
                presswork_message: payload
            };
        case ADD_PRESSWORK_ENDED:
            return {
                ...state,
                add_presswork_loading: false
            };
        case GET_PRESSWORK_STATED:
            return {
                ...state,
                presswork: null,
                presswork_loading: true
            };
        case GET_PRESSWORK:
            return {
                ...state,
                presswork: payload
            };
        case GET_PRESSWORK_ENDED:
            return {
                ...state,
                presswork_loading: false
            };
        case EDIT_PRESSWORK_STATED:
            return {
                ...state,
                presswork_message: null,
                edit_presswork_loading: true
            };
        case EDIT_PRESSWORK:
            return {
                ...state,
                presswork_message: payload
            };
        case EDIT_PRESSWORK_ENDED:
            return {
                ...state,
                edit_presswork_loading: false
            };

        default:
            return state;
    }
};
