export const GET_PRESSWORKS_STATED = "GET_PRESSWORKS_STATED";
export const GET_PRESSWORKS = "GET_PRESSWORKS";
export const GET_PRESSWORKS_ENDED = "GET_PRESSWORKS_ENDED";
export const ADD_PRESSWORK_STATED = "ADD_PRESSWORK_STARTED";
export const ADD_PRESSWORK = "ADD_PRESSWORK";
export const ADD_PRESSWORK_ENDED = "ADD_PRESSWORK_ENDED";
export const EDIT_PRESSWORK_STATED = "EDIT_PRESSWORK_STATED";
export const EDIT_PRESSWORK = "EDIT_PRESSWORK";
export const EDIT_PRESSWORK_ENDED = "EDIT_PRESSWORK_ENDED";
export const GET_PRESSWORK = "GET_PRESSWORK";
export const GET_PRESSWORK_STATED = "GET_PRESSWORK_STATED";
export const GET_PRESSWORK_ENDED = "GET_PRESSWORK_ENDED";
export const RESET_PRESSWORK = "RESET_PRESSWORK";
export const ERROR_PRESSWORK = "ERROR_PRESSWORK";
export const GET_ALL_PRESSWORKS_STATED = "GET_ALL_PRESSWORKS_STATED";
export const GET_ALL_PRESSWORKS = "GET_ALL_PRESSWORKS";
export const GET_ALL_PRESSWORKS_ENDED = "GET_ALL_PRESSWORKS_ENDED";
